
import React from 'react';
import { ISample } from './types';


export const Sample: ISample = ({
  text,
  onClick,
}) => (
  <div
    className="btn btn-outline-secondary fs-5 text-start rounded-5 p-3 position-relative"
    onClick={ onClick }
  >
    { text }
    <i className="bi bi-box-arrow-up position-absolute" />
  </div>
);

