import React from 'react';

import { Sample } from './Sample';
import { questions } from './consts';
import { ISamples } from './types';

import './styles.scss';

export const Samples: ISamples = ({
  onClick,
}) => (
  <div className='samples my-2'>
    <h6 className='samples__header fw-bold fs-5 mb-2'>Try asking</h6>

    <div className='samples__container d-grid gap-3 fs-5'>
      {
        questions.map(q =>
          <Sample
            key={ q }
            text={ q }
            onClick={ () => onClick(q) }
          />,
        )
      }
    </div>
  </div>
);
