import React from 'react';

import { Menu, Pages } from 'components';
import './styles.scss';

export const Layout: React.FC = () => (
  <div className='layout d-flex flex-sm-row flex-column h-100'>
    <Menu />
    <div className='page-container d-flex flex-column w-100'>
      <div
        className='brand d-flex pe-5 justify-content-end fw-bold fs-2 pt-4'
        onClick={ () => window.location.href = '/' }
      >askWMO<span><sub className='fw-normal fs-5'>pilot</sub></span>
      </div>
      <Pages />
    </div>
  </div>
);
