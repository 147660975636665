import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MsalProvider } from '@azure/msal-react';

import { Layout } from './components';

import './App.scss';

const App: React.FC<{ msalInstance: any }> = ({ msalInstance }) => (
  <MsalProvider instance={ msalInstance }>
    <Layout />
  </MsalProvider>
);

export default App;
