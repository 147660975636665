import { TableDBProp } from './TableDb';

/* eslint-disable max-len */
export const DB_ITEMS: TableDBProp[] = [
  {
    title: 'A. Regional Association Reports',
    contentList: [
      {
        fileName: 'Regional Association I (Africa), Abridged Final Report of the 18th Session, Abridged Final Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1266',
        permalink: 'https://library.wmo.int/idurl/4/57803',
        pdfUrl: 'https://library.wmo.int/viewer/57803/download?file=1266_en.pdf&type=pdf&navigator=1',
        numberOfPages: 80,
      },
      {
        fileName: 'Regional Association I (Africa), Abridged Final Report of the 18th Session, Progress Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1266',
        permalink: 'https://library.wmo.int/idurl/4/57803',
        pdfUrl: 'https://library.wmo.int/viewer/57803/download?file=Part_II_Progress_report.pdf&type=pdf&navigator=1',
        numberOfPages: 319,
      },
      {
        fileName: 'Regional Association I (Africa), Abridged Final Report of the 18th Session, General Considerations',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1266',
        permalink: 'https://library.wmo.int/idurl/4/57803',
        pdfUrl: 'https://library.wmo.int/viewer/57803/download?file=General_Considerations_RA_I-18.pdf&type=pdf&navigator=1',
        numberOfPages: 2,
      },
      {
        fileName: 'Regional Association VI (Europe), Abridged Final Report of the 18th Session, Part I Abridged Final Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1260',
        permalink: 'https://library.wmo.int/idurl/4/57474',
        pdfUrl: 'https://library.wmo.int/viewer/57474/download?file=1260_en.pdf&type=pdf&navigator=1',
        numberOfPages: 49,
      },
      {
        fileName: 'Regional Association VI (Europe), Abridged Final Report of the 18th Session, Part II Progress Report (Multilingual)',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1260',
        permalink: 'https://library.wmo.int/idurl/4/57474',
        pdfUrl: 'https://library.wmo.int/viewer/57474/download?file=RA-VI-18-Part_II.pdf&type=pdf&navigator=1',
        numberOfPages: 92,
      },
      {
        fileName: 'Regional Association IV (North America, Central America, and the Caribbean), Abridged Final Report of the 18th Session, Part I Abridged Final Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1265',
        permalink: 'https://library.wmo.int/idurl/4/57465',
        pdfUrl: 'https://library.wmo.int/viewer/57465/download?file=1265_RA_IV-18_en.pdf&type=pdf&navigator=1',
        numberOfPages: 64,
      },
      {
        fileName: 'Regional Association IV (North America, Central America, and the Caribbean), Abridged Final Report of the 18th Session, Part II Progress Report (Multilingual)',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1265',
        permalink: 'https://library.wmo.int/idurl/4/57465',
        pdfUrl: 'https://library.wmo.int/viewer/57465/download?file=RA-IV-18_Part-II.pdf&type=pdf&navigator=1',
        numberOfPages: 63,
      },
      {
        fileName: 'Regional Association V (South-West Pacific), Abridged Final Report of the 18th Session, Part I Abridged Final Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1280',
        permalink: 'https://library.wmo.int/idurl/4/57887',
        pdfUrl: 'https://library.wmo.int/viewer/57887/download?file=1280_en.pdf&type=pdf&navigator=1',
        numberOfPages: 56,
      },
      {
        fileName: 'Regional Association V (South-West Pacific), Abridged Final Report of the 18th Session, Part II Progress Report (Multilingual)',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1280',
        permalink: 'https://library.wmo.int/idurl/4/57887',
        pdfUrl: 'https://library.wmo.int/viewer/57887/download?file=Part_II_ProgressActivity_report_RA_V.pdf&type=pdf&navigator=1',
        numberOfPages: 53,
      },
      {
        fileName: 'Regional Association II (Asia), Abridged Final Report of the 17th Session, Part I Abridged Final Report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1279',
        permalink: 'https://library.wmo.int/idurl/4/57816',
        pdfUrl: 'https://library.wmo.int/viewer/57816/download?file=1279_en.pdf&type=pdf&navigator=1',
        numberOfPages: 61,
      },
      {
        fileName: 'Regional Association II (Asia), Abridged Final Report of the 17th Session, Part II Progress Report (Multilingual)',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1279',
        permalink: 'https://library.wmo.int/idurl/4/57816',
        pdfUrl: 'https://library.wmo.int/viewer/57816/download?file=Part_II_ProgressActivity_report_1.pdf&type=pdf&navigator=1',
        numberOfPages: 304,
      },
      {
        fileName: 'Regional Association III (South America), Abridged Final Report of the 18th Session, Part I Abridged Final Report',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1261',
        permalink: 'https://library.wmo.int/idurl/4/57304',
        pdfUrl: 'https://library.wmo.int/viewer/57304/download?file=1261-RA-III-18_en.pdf&type=pdf&navigator=1',
        numberOfPages: 34,
      },
      {
        fileName: 'Regional Association III (South America), Abridged Final Report of the 18th Session, Part II Progress Report (Multilingual)',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1261',
        permalink: 'https://library.wmo.int/idurl/4/57304',
        pdfUrl: 'https://library.wmo.int/viewer/57304/download?file=AR-III-18-Activity-Report.pdf&type=pdf&navigator=1',
        numberOfPages: 31,
      },
    ],
  },
  {
    title: 'B. Basic Document',
    contentList: [
      {
        fileName: 'Basic Document No. 1 (Convention, General Regulations, Staff Regulations, Financial Regulations, and Agreements)',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 15',
        permalink: 'https://library.wmo.int/idurl/4/48992',
        pdfUrl: 'https://library.wmo.int/viewer/48992/download?file=15_2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 212,
      },
    ],
  },
  {
    title: 'C. Rules of Procedure',
    contentList: [
      {
        fileName: 'Rules of Procedure for the Executive Council and for the Bodies Reporting to the Executive Council',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1256',
        permalink: 'https://library.wmo.int/idurl/4/57272',
        pdfUrl: 'https://library.wmo.int/viewer/57272/download?file=1256-2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 64,
      },
      {
        fileName: 'Rules of Procedure for Technical Commissions',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1240',
        permalink: 'https://library.wmo.int/idurl/4/56841',
        pdfUrl: 'https://library.wmo.int/viewer/56841/download?file=1240-2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 41,
      },
      {
        fileName: 'Rules of Procedure for Regional Associations',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1241',
        permalink: 'https://library.wmo.int/idurl/4/56848',
        pdfUrl: 'https://library.wmo.int/viewer/56848/download?file=1241_en.pdf&type=pdf&navigator=1',
        numberOfPages: 19,
      },
    ],
  },
  {
    title: 'D. INFCOM',
    contentList: [
      {
        fileName: 'Commission for Observation, Infrastructure and Information Systems, Abridged Final Report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1306',
        permalink: 'https://library.wmo.int/idurl/4/66287',
        pdfUrl: 'https://library.wmo.int/viewer/66287/download?file=1306_en.pdf&type=pdf&navigator=1',
        numberOfPages: 1121,
      },
      {
        fileName: 'Commission for Observation, Infrastructure and Information Systems, General Considerations',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1306',
        permalink: 'https://library.wmo.int/idurl/4/66287',
        pdfUrl: 'https://library.wmo.int/viewer/66287/download?file=INFCOM-2__GENERAL_CONSIDERATIONS_en.pdf&type=pdf&navigator=1',
        numberOfPages: 13,
      },
      {
        fileName: 'Commission for Observation, Infrastructure and Information Systems, Progress Activity Report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1306',
        permalink: 'https://library.wmo.int/idurl/4/66287',
        pdfUrl: 'https://library.wmo.int/viewer/66287/download?file=INFCOM-2-INF_docs_en.pdf&type=pdf&navigator=1',
        numberOfPages: 955,
      },
      {
        fileName: 'INFCOM-1; Commission for Observation, Infrastructure and Information Systems; Abridged Final Report of the First Session',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1251',
        permalink: 'https://library.wmo.int/idurl/4/57371',
        pdfUrl: 'https://library.wmo.int/viewer/57371/download?file=1251-INFCOM-1_en.pdf&type=pdf&navigator=1',
        numberOfPages: 531,
      },
      {
        fileName: 'INFCOM-1; Commission for Observation, Infrastructure and Information Systems; Abridged Final Report of the First Session; Part II',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1251',
        permalink: 'https://library.wmo.int/idurl/4/57371',
        pdfUrl: 'https://library.wmo.int/viewer/57371/download?file=Part_II_INFCOM-1.pdf_en.pdf&type=pdf&navigator=1',
        numberOfPages: 301,
      },
    ],
  },
  {
    title: 'E. SERCOM',
    contentList: [
      {
        fileName: 'Commission for Weather, Climate, Water and Related Environmental Services and Applications, Abridged Final Report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1307',
        permalink: 'https://library.wmo.int/idurl/4/39647',
        pdfUrl: 'https://library.wmo.int/viewer/39647/download?file=1307_en.pdf&type=pdf&navigator=1',
        numberOfPages: 400,
      },
      {
        fileName: 'Commission for Weather, Climate, Water and Related Environmental Services and Applications, General Considerations',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1307',
        permalink: 'https://library.wmo.int/idurl/4/39647',
        pdfUrl: 'https://library.wmo.int/viewer/39647/download?file=SERCOM-2_GENERAL_CONSIDERATIONS.pdf&type=pdf&navigator=1',
        numberOfPages: 42,
      },
      {
        fileName: 'Commission for Weather, Climate, Water and Related Environmental Services and Applications, Progress Report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1307',
        permalink: 'https://library.wmo.int/idurl/4/39647',
        pdfUrl: 'https://library.wmo.int/viewer/39647/download?file=SERCOM-2-PROGRESS_REPORT.pdf&type=pdf&navigator=1',
        numberOfPages: 505,
      },
      {
        fileName: 'SERCOM-1; Commission for Weather, Climate, Water and Related Environmental Services and Applications',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1259',
        permalink: 'https://library.wmo.int/idurl/4/57556',
        pdfUrl: 'https://library.wmo.int/viewer/57556/download?file=1259_en.pdf&type=pdf&navigator=1',
        numberOfPages: 188,
      },
      {
        fileName: 'SERCOM-1; Commission for Weather, Climate, Water and Related Environmental Services and Applications; Part II',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1259',
        permalink: 'https://library.wmo.int/idurl/4/57556',
        pdfUrl: 'https://library.wmo.int/viewer/57556/download?file=Part_II_SERCOM-1.pdf&type=pdf&navigator=1',
        numberOfPages: 114,
      },
    ],
  },
  {
    title: 'F. Technical Regulations',
    contentList: [
      {
        fileName: 'Technical Regulations, Volume II – Meteorological Service for International Air Navigation',
        yearPublished: 2018,
        officialNumber: 'WMO-No. 49',
        permalink: 'https://library.wmo.int/idurl/4/35795',
        pdfUrl: 'https://library.wmo.int/viewer/35795/download?file=WMO-No49_Vol-II_2018-upd-2021_Met-Service_en.pdf&type=pdf&navigator=1',
        numberOfPages: 206,
      },
      {
        fileName: 'Technical Regulations, Volume III – Hydrology',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 49',
        permalink: 'https://library.wmo.int/idurl/4/35631',
        pdfUrl: 'https://library.wmo.int/viewer/35631/download?file=49_III_en.pdf&type=pdf&navigator=1',
        numberOfPages: 40,
      },
      {
        fileName: 'Technical Regulations, Volume I – General Meteorological Standards and Recommended Practices',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 49',
        permalink: 'https://library.wmo.int/idurl/4/35722',
        pdfUrl: 'https://library.wmo.int/viewer/35722/download?file=49-I_en.pdf&type=pdf&navigator=1',
        numberOfPages: 71,
      },
    ],
  },
  {
    title: 'G. Manuals-Annexes to TRs',
    contentList: [
      {
        fileName: 'Manual on Codes, Volume I.1 – International Codes, Annex II to the WMO Technical Regulations, Part A – Alphanumeric Codes',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 306',
        permalink: 'https://library.wmo.int/idurl/4/35713',
        pdfUrl: 'https://library.wmo.int/viewer/35713/download?file=306_i1_2019_en.pdf&type=pdf&navigator=1',
        numberOfPages: 480,
      },
      {
        fileName: 'Manual on Codes, Volume I.2 – International Codes, Annex II to the WMO Technical Regulations, Part B – Binary Codes, Part C – Common Features to Binary and Alphanumeric Codes',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 306',
        permalink: 'https://library.wmo.int/idurl/4/35625',
        pdfUrl: 'https://library.wmo.int/viewer/35625/download?file=306_v.I.2_2019_edition_Updated_2022_en.pdf&type=pdf&navigator=1',
        numberOfPages: 1238,
      },
      {
        fileName: 'Manual on Codes, Volume I.3 – International Codes, Annex II to the WMO Technical Regulations, Part D – Representations derived from data models',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 306',
        permalink: 'https://library.wmo.int/idurl/4/35769',
        pdfUrl: 'https://library.wmo.int/viewer/35769/download?file=WMO-No306_Vol-I-3_2019-upd-2021_Manual-on-Codes_en.pdf&type=pdf&navigator=1',
        numberOfPages: 272,
      },
      {
        fileName: 'Manual on Marine Meteorological Services, Volume I – Global Aspects, Annex VI to the WMO Technical Regulations',
        yearPublished: 2012,
        officialNumber: 'WMO-No. 558',
        permalink: 'https://library.wmo.int/idurl/4/41585',
        pdfUrl: 'https://library.wmo.int/viewer/41585/download?file=558-2012-2018_en.pdf&type=pdf&navigator=1',
        numberOfPages: 73,
      },
      {
        fileName: 'Manual on the Global Data-processing and Forecasting System, Annex IV to the WMO Technical Regulations',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 485',
        permalink: 'https://library.wmo.int/idurl/4/35703',
        pdfUrl: 'https://library.wmo.int/viewer/35703/download?file=485_en.pdf&type=pdf&navigator=1',
        numberOfPages: 153,
      },
      {
        fileName: 'Manual on the Global Observing System, Volume I – Global aspects Annex V to the WMO Technical Regulations',
        yearPublished: 2015,
        officialNumber: 'WMO-No. 544',
        permalink: 'https://library.wmo.int/idurl/4/58672',
        pdfUrl: 'https://library.wmo.int/viewer/58672/download?file=544-Vol-I-Ed-2015-Update-2017_en.pdf&type=pdf&navigator=1',
        numberOfPages: 92,
      },
      {
        fileName: 'Manual on the Global Telecommunication System, Annex III to the WMO Technical Regulations',
        yearPublished: 2015,
        officialNumber: 'WMO-No. 386',
        permalink: 'https://library.wmo.int/idurl/4/35800',
        pdfUrl: 'https://library.wmo.int/viewer/35800/download?file=386_en_1.pdf&type=pdf&navigator=1',
        numberOfPages: 200,
      },
      {
        fileName: 'Manual on the High-quality Global Data Management Framework for Climate, Annex IX to the WMO Technical Regulations',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1238',
        permalink: 'https://library.wmo.int/idurl/4/56975',
        pdfUrl: 'https://library.wmo.int/viewer/56975/download?file=1238_en.pdf&type=pdf&navigator=1',
        numberOfPages: 43,
      },
      {
        fileName: 'Manual on the Observation of Clouds and Other Meteors – International Cloud Atlas; Annex I to the WMO Technical Regulations',
        yearPublished: 2017,
        officialNumber: 'WMO-No. 407',
        permalink: 'https://cloudatlas.wmo.int/en/home.html',
        pdfUrl: 'NA',
        numberOfPages: 0,
      },
      {
        fileName: 'Manual on the WMO Information System, Annex VII to the WMO Technical Regulations',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1060',
        permalink: 'https://library.wmo.int/idurl/4/35315',
        pdfUrl: 'https://library.wmo.int/viewer/35315/download?file=1060_en.pdf&type=pdf&navigator=1',
        numberOfPages: 122,
      },
      {
        fileName: 'Manual on the WMO Integrated Global Observing System, Annex VIII to the WMO Technical Regulations',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1160',
        permalink: 'https://library.wmo.int/idurl/4/55063',
        pdfUrl: 'https://library.wmo.int/viewer/55063/download?file=1160_en.pdf&type=pdf&navigator=1',
        numberOfPages: 158,
      },
    ],
  },
  {
    title: 'H. Cg',
    contentList: [
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Nineteenth Session: Abridged Final Report of the Nineteenth Session',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1326',
        permalink: 'https://library.wmo.int/idurl/4/67177',
        pdfUrl: 'https://library.wmo.int/viewer/67177/download?file=1326_Cg-19_en.pdf&type=pdf&navigator=1',
        numberOfPages: 604,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Nineteenth Session: General considerations of the Nineteenth Session',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1326',
        permalink: 'https://library.wmo.int/idurl/4/67177',
        pdfUrl: 'https://library.wmo.int/viewer/67177/download?file=Cg-19_General-considerations_en.pdf&type=pdf&navigator=1',
        numberOfPages: 39,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Nineteenth Session: Progress report of the Nineteenth Session',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1326',
        permalink: 'https://library.wmo.int/idurl/4/67177',
        pdfUrl: 'https://library.wmo.int/viewer/67177/download?file=Cg-19_Progress-report_en.pdf&type=pdf&navigator=1',
        numberOfPages: 1580,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Nineteenth Session: Statements of the Nineteenth Session',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1326',
        permalink: 'https://library.wmo.int/idurl/4/67177',
        pdfUrl: 'https://library.wmo.int/viewer/67177/download?file=Statements_Cg-19.pdf&type=pdf&navigator=1',
        numberOfPages: 6,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Extraordinary Session, Cg-Ext(2021), Part I: Abridged final report of Extraordinary Session',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1281',
        permalink: 'https://library.wmo.int/idurl/4/57850',
        pdfUrl: 'https://library.wmo.int/viewer/57850/download?file=1281_en.pdf&type=pdf&navigator=1',
        numberOfPages: 253,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Extraordinary Session, Cg-Ext(2021), Part II: Progress report (multilingual) of Extraordinary Session',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1281',
        permalink: 'https://library.wmo.int/idurl/4/57850',
        pdfUrl: 'https://library.wmo.int/viewer/57850/download?file=Part_II_ProgressActivity_report_5.pdf&type=pdf&navigator=1',
        numberOfPages: 206,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Eighteenth Session: Abridged Final Report of the Eighteenth Session Part I',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1236',
        permalink: 'https://library.wmo.int/idurl/4/56690',
        pdfUrl: 'https://library.wmo.int/viewer/56690/download?file=1236_en.pdf&type=pdf&navigator=1',
        numberOfPages: 316,
      },
      {
        fileName: 'World Meteorological Congress; Abridged Final Report of the Eighteenth Session: Part II - Progress Activity Report (multilingual) _ (unedited and in official WMO languages when available)',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1236',
        permalink: 'https://library.wmo.int/idurl/4/56690',
        pdfUrl: 'https://library.wmo.int/viewer/56690/download?file=1236-part2_multilingual.pdf&type=pdf&navigator=1',
        numberOfPages: 1325,
      },
      {
        fileName: 'Seventeenth World Meteorological Congress; Abridged Final Report with Resolutions; Part I - Abridged final report with resolutions',
        yearPublished: 2015,
        officialNumber: 'WMO-No. 1157',
        permalink: 'https://library.wmo.int/idurl/4/54771',
        pdfUrl: 'https://library.wmo.int/viewer/54771/download?file=1157_en.pdf&type=pdf&navigator=1',
        numberOfPages: 708,
      },
      {
        fileName: 'Seventeenth World Meteorological Congress; Abridged Final Report with Resolutions; Part II - Progress Report',
        yearPublished: 2015,
        officialNumber: 'WMO-No. 1157',
        permalink: 'https://library.wmo.int/idurl/4/54771',
        pdfUrl: 'https://library.wmo.int/viewer/54771/download?file=1157-p2_en.pdf&type=pdf&navigator=1',
        numberOfPages: 463,
      },
      {
        fileName: 'World Meteorological Congress – Extraordinary session; Abridged Final Report with Resolutions; Part I - Abridged final report',
        yearPublished: 2012,
        officialNumber: 'WMO-No. 1102',
        permalink: 'https://library.wmo.int/idurl/4/48901',
        pdfUrl: 'https://library.wmo.int/viewer/48901/download?file=1102-p1_en.pdf&type=pdf&navigator=1',
        numberOfPages: 126,
      },
      {
        fileName: 'World Meteorological Congress – Extraordinary session; Abridged Final Report with Resolutions; Part II - Progress Report',
        yearPublished: 2012,
        officialNumber: 'WMO-No. 1102',
        permalink: 'https://library.wmo.int/idurl/4/48901',
        pdfUrl: 'https://library.wmo.int/viewer/48901/download?file=1102-p2_en.pdf&type=pdf&navigator=1',
        numberOfPages: 369,
      },
      {
        fileName: 'Sixteenth World Meteorological Congress; Abridged final report with resolutions; Part I - Abridged final report with resolutions',
        yearPublished: 2011,
        officialNumber: 'WMO-No. 1077',
        permalink: 'https://library.wmo.int/idurl/4/29022',
        pdfUrl: 'https://library.wmo.int/viewer/29022/download?file=1077_en.pdf&type=pdf&navigator=1',
        numberOfPages: 494,
      },
      {
        fileName: 'Sixteenth World Meteorological Congress; Abridged final report with resolutions; Part II - Progress Report',
        yearPublished: 2011,
        officialNumber: 'WMO-No. 1077',
        permalink: 'https://library.wmo.int/idurl/4/29022',
        pdfUrl: 'https://library.wmo.int/viewer/29022/download?file=1077-p2_en.pdf&type=pdf&navigator=1',
        numberOfPages: 120,
      },
      {
        fileName: 'Fifteenth World Meteorological Congress; Abridged final report with resolutions; Part I - Abridged final report',
        yearPublished: 2007,
        officialNumber: 'WMO-No. 1026',
        permalink: 'https://library.wmo.int/idurl/4/32226',
        pdfUrl: 'https://library.wmo.int/viewer/32226/download?file=1026_en.pdf&type=pdf&navigator=1',
        numberOfPages: 302,
      },
      {
        fileName: 'Fifteenth World Meteorological Congress; Abridged final report with resolutions; Part II - Progress Report',
        yearPublished: 2007,
        officialNumber: 'WMO-No. 1026',
        permalink: 'https://library.wmo.int/idurl/4/32226',
        pdfUrl: 'https://library.wmo.int/viewer/32226/download?file=1026-p2_en.pdf&type=pdf&navigator=1',
        numberOfPages: 293,
      },
      {
        fileName: 'Fourteenth World Meteorological Congress; Proceedings; PROCEEDINGS',
        yearPublished: 2004,
        officialNumber: 'WMO-No. 972',
        permalink: 'https://library.wmo.int/idurl/4/32552',
        pdfUrl: 'https://library.wmo.int/viewer/32552/download?file=wmo_972_en.pdf&type=pdf&navigator=1',
        numberOfPages: 101,
      },
      {
        fileName: 'Fourteenth World Meteorological Congress; Abridged Final Report with Resolutions',
        yearPublished: 2003,
        officialNumber: 'WMO-No. 960',
        permalink: 'https://library.wmo.int/idurl/4/58742',
        pdfUrl: 'https://library.wmo.int/viewer/58742/download?file=wmo_960_Convert_en.pdf&type=pdf&navigator=1',
        numberOfPages: 233,
      },
    ],
  },
  {
    title: 'I. EC',
    contentList: [
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-seventh Session; Abridged final report',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1327',
        permalink: 'https://library.wmo.int/idurl/4/66333',
        pdfUrl: 'https://library.wmo.int/viewer/66333/download?file=1327_EC-77_en.pdf&type=pdf&navigator=1',
        numberOfPages: 60,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-seventh Session; General considerations',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1327',
        permalink: 'https://library.wmo.int/idurl/4/66333',
        pdfUrl: 'https://library.wmo.int/viewer/66333/download?file=EC-77_General_considerations.pdf&type=pdf&navigator=1',
        numberOfPages: 12,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-seventh Session; Progress report',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1327',
        permalink: 'https://library.wmo.int/idurl/4/66333',
        pdfUrl: 'https://library.wmo.int/viewer/66333/download?file=EC-77-Progress_report_en.pdf&type=pdf&navigator=1',
        numberOfPages: 218,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-sixth Session; Abridged final report',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1314',
        permalink: 'https://library.wmo.int/idurl/4/66258',
        pdfUrl: 'https://library.wmo.int/viewer/66258/download?file=1314_en.pdf&type=pdf&navigator=1',
        numberOfPages: 1461,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-sixth Session; General considerations',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1314',
        permalink: 'https://library.wmo.int/idurl/4/66258',
        pdfUrl: 'https://library.wmo.int/viewer/66258/download?file=EC-76_General+considerations_en.pdf&type=pdf&navigator=1',
        numberOfPages: 61,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-sixth Session; Progress report',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1314',
        permalink: 'https://library.wmo.int/idurl/4/66258',
        pdfUrl: 'https://library.wmo.int/viewer/66258/download?file=EC-76-PROGRESS-REPORT_en.pdf&type=pdf&navigator=1',
        numberOfPages: 1227,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-fifth Session; Geneva, Abridged final report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1297',
        permalink: 'https://library.wmo.int/idurl/4/57844',
        pdfUrl: 'https://library.wmo.int/viewer/58160/download?file=1297_en.pdf&type=pdf&navigator=1',
        numberOfPages: 164,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-fifth Session; Geneva, Progress report',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1297',
        permalink: 'https://library.wmo.int/idurl/4/57844',
        pdfUrl: 'https://library.wmo.int/viewer/58160/download?file=Progress_report_en.pdf&type=pdf&navigator=1',
        numberOfPages: 854,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-fourth Session, Part I: Abridged final report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1282',
        permalink: 'https://library.wmo.int/records/item/57844-executive-council',
        pdfUrl: 'https://library.wmo.int/viewer/57844/download?file=1282_en.pdf&type=pdf&navigator=1',
        numberOfPages: 39,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-fourth Session, Part I: Abridged final report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1282',
        permalink: 'https://library.wmo.int/records/item/57844-executive-council',
        pdfUrl: 'https://library.wmo.int/viewer/57844/download?file=Part_II_ProgressActivity_report_3.pdf&type=pdf&navigator=1',
        numberOfPages: 25,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-third Session; Part I: Abridged final report',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1277',
        permalink: 'https://library.wmo.int/idurl/4/57838',
        pdfUrl: 'https://library.wmo.int/viewer/57838/download?file=1277_en.pdf&type=pdf&navigator=1',
        numberOfPages: 607,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-third Session; Part II: Progress report (multilingual)',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1277',
        permalink: 'https://library.wmo.int/idurl/4/57838',
        pdfUrl: 'https://library.wmo.int/viewer/57838/download?file=Part_II_ProgressActivity_report_2.pdf&type=pdf&navigator=1',
        numberOfPages: 686,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-second Session; Part I - Abridged Final Report',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1255',
        permalink: 'https://library.wmo.int/idurl/4/57221',
        pdfUrl: 'https://library.wmo.int/viewer/57221/download?file=1255_en.pdf&type=pdf&navigator=1',
        numberOfPages: 166,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-second Session; Part II - Progress Report (multilingual)',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1255',
        permalink: 'https://library.wmo.int/idurl/4/57221',
        pdfUrl: 'https://library.wmo.int/viewer/57221/download?file=EC-72_Part_II.pdf&type=pdf&navigator=1',
        numberOfPages: 524,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-first Session; Part I - Abridged Final Report',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1237',
        permalink: 'https://library.wmo.int/idurl/4/56698',
        pdfUrl: 'https://library.wmo.int/viewer/56698/download?file=1237_en.pdf&type=pdf&navigator=1',
        numberOfPages: 80,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventy-first Session; Part II - Progress Report (multilingual)',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1237',
        permalink: 'https://library.wmo.int/idurl/4/56698',
        pdfUrl: 'https://library.wmo.int/viewer/56698/download?file=1237-part2_multilingual.pdf&type=pdf&navigator=1',
        numberOfPages: 131,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventieth Session; Part I - Abridged final report',
        yearPublished: 2018,
        officialNumber: 'WMO-No. 1218',
        permalink: 'https://library.wmo.int/idurl/4/56243',
        pdfUrl: 'https://library.wmo.int/viewer/56243/download?file=1218_en.pdf&type=pdf&navigator=1',
        numberOfPages: 358,
      },
      {
        fileName: 'Executive Council; Abridged Final Report of the Seventieth Session; Part II - Progress Report  (multilingual)',
        yearPublished: 2018,
        officialNumber: 'WMO-No. 1218',
        permalink: 'https://library.wmo.int/idurl/4/56243',
        pdfUrl: 'https://library.wmo.int/viewer/56243/download?file=1218_part-ii_multilingual.pdf&type=pdf&navigator=1',
        numberOfPages: 1058,
      },
      {
        fileName: 'Executive Council – Sixty-ninth session; Abridged final report with resolutions and decisions; Part I - Abridged final report with resolutions and decisions',
        yearPublished: 2017,
        officialNumber: 'WMO-No. 1196',
        permalink: 'https://library.wmo.int/idurl/4/55618',
        pdfUrl: 'https://library.wmo.int/viewer/55618/download?file=1196_en.pdf&type=pdf&navigator=1',
        numberOfPages: 331,
      },
      {
        fileName: 'Executive Council – Sixty-ninth session; Abridged final report with resolutions and decisions; Part II - Progress report',
        yearPublished: 2017,
        officialNumber: 'WMO-No. 1196',
        permalink: 'https://library.wmo.int/idurl/4/55618',
        pdfUrl: 'https://library.wmo.int/viewer/55618/download?file=EC-69_Progress_report_en.pdf&type=pdf&navigator=1',
        numberOfPages: 850,
      },
    ],
  },
  {
    title: 'J. Guides',
    contentList: [
      {
        fileName: 'Guidelines for the Development and Adoption of Resolutions, Decisions and Recommendations',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 508',
        permalink: 'https://library.wmo.int/idurl/4/51026',
        pdfUrl: 'https://library.wmo.int/viewer/51026/download?file=508-2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 72,
      },
      {
        fileName: 'WMO Guide for National Meteorological and Hydrological Services in Support of National Multi hazard Early Warning Systems, Procedures, Coordination Mechanisms and Services; Guide No. 1 – Tropical Cyclones',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1339',
        permalink: 'https://library.wmo.int/idurl/4/68706',
        pdfUrl: 'https://library.wmo.int/viewer/68706/download?file=1339_en.pdf&type=pdf&navigator=1',
        numberOfPages: 92,
      },
      {
        fileName: 'WMO 2022 Global Survey on Satellite Data Utilization',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1342',
        permalink: 'https://library.wmo.int/idurl/4/68701',
        pdfUrl: 'https://library.wmo.int/viewer/68701/download?file=1342_en.pdf&type=pdf&navigator=1',
        numberOfPages: 141,
      },
      {
        fileName: 'Guidelines for the WMO Evaluation of Records of Weather and Climate Extremes',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1317',
        permalink: 'https://library.wmo.int/idurl/4/68658',
        pdfUrl: 'https://library.wmo.int/viewer/68658/download?file=1317_2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 47,
      },
      {
        fileName: 'Guide to Aeronautical Meteorological Services Cost Recovery; Principles and Guidance',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 904',
        permalink: 'https://library.wmo.int/idurl/4/31999',
        pdfUrl: 'https://library.wmo.int/viewer/31999/download?file=904_2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 69,
      },
      {
        fileName: 'Guide to Climatological Practices',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 100',
        permalink: 'https://library.wmo.int/idurl/4/60113',
        pdfUrl: 'https://library.wmo.int/viewer/60113/download?file=100_2023-edition_en.pdf&type=pdf&navigator=1',
        numberOfPages: 99,
      },
      {
        fileName: 'Guide to the Implementation of Education and Training Standards in Meteorology and Hydrology; Volume I – Meteorology',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1083',
        permalink: 'https://library.wmo.int/idurl/4/35676',
        pdfUrl: 'https://library.wmo.int/viewer/35676/download?file=1083_en.pdf&type=pdf&navigator=1',
        numberOfPages: 69,
      },
      {
        fileName: 'OSCAR/Surface User Manual',
        yearPublished: 2023,
        officialNumber: '',
        permalink: 'https://library.wmo.int/idurl/4/56451',
        pdfUrl: 'https://library.wmo.int/viewer/56451/download?file=OSCAR_1.9.1_en.pdf&type=pdf&navigator=1',
        numberOfPages: 88,
      },
      {
        fileName: 'Supporting Water Vapour Measurements within an Aircraft Meteorological Data Relay Programme; Business Case Guidance for National Meteorological and Hydrological Services',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1325',
        permalink: 'https://library.wmo.int/idurl/4/68416',
        pdfUrl: 'https://library.wmo.int/viewer/68416/download?file=1325_en.pdf&type=pdf&navigator=1',
        numberOfPages: 24,
      },
      {
        fileName: 'Guidelines for Satellite-based Nowcasting in Africa',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1309',
        permalink: 'https://library.wmo.int/idurl/4/58348',
        pdfUrl: 'https://library.wmo.int/viewer/58348/download?file=1309_en.pdf&type=pdf&navigator=1',
        numberOfPages: 49,
      },
      {
        fileName: 'Guidance on Measuring, Modelling and Monitoring the Canopy Layer Urban Heat Island (CL UHI)',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1292',
        permalink: 'https://library.wmo.int/idurl/4/58410',
        pdfUrl: 'https://library.wmo.int/viewer/58410/download?file=1292_en.pdf&type=pdf&navigator=1',
        numberOfPages: 101,
      },
      {
        fileName: 'Guidelines on the Definition and Characterization of Extreme Weather and Climate Events',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1310',
        permalink: 'https://library.wmo.int/idurl/4/58396',
        pdfUrl: 'https://library.wmo.int/viewer/58396/download?file=1310_Guidelines_on_DEWCE_en.pdf&type=pdf&navigator=1',
        numberOfPages: 36,
      },
      {
        fileName: 'Guidelines on High-resolution Numerical Weather Prediction',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1311',
        permalink: 'https://library.wmo.int/idurl/4/66217',
        pdfUrl: 'https://library.wmo.int/viewer/66217/download?file=1311_en.pdf&type=pdf&navigator=1',
        numberOfPages: 44,
      },
      {
        fileName: 'Guidance Booklet: WMO Stewardship Maturity Matrix for Climate Data',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1328',
        permalink: 'https://library.wmo.int/idurl/4/68238',
        pdfUrl: 'https://library.wmo.int/viewer/68238/download?file=1328_en.pdf&type=pdf&navigator=1',
        numberOfPages: 35,
      },
      {
        fileName: 'Integrated Weather and Climate Services in Support of Net Zero Energy Transition; Best Practices from the WMO Commission for Weather, Climate, Water and Related Environmental Services and Applications',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1312',
        permalink: 'https://library.wmo.int/idurl/4/66273',
        pdfUrl: 'https://library.wmo.int/viewer/66273/download?file=1312_en.pdf&type=pdf&navigator=1',
        numberOfPages: 169,
      },
      {
        fileName: 'Step-by-step Guidelines for the Elaboration of Regional State of the Climate Reports',
        yearPublished: 2023,
        officialNumber: 'WMO-No. 1298',
        permalink: 'https://library.wmo.int/idurl/4/66285',
        pdfUrl: 'https://library.wmo.int/viewer/66285/download?file=1298_en.pdf&type=pdf&navigator=1',
        numberOfPages: 49,
      },
      {
        fileName: 'A Primer on Public Policy and Management with a Focus on National Meteorological and Hydrological Services',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1289',
        permalink: 'https://library.wmo.int/idurl/4/58042',
        pdfUrl: 'https://library.wmo.int/viewer/58042/download?file=1289_en.pdf&type=pdf&navigator=1',
        numberOfPages: 91,
      },
      {
        fileName: 'Guidelines on Communicating Climate Science and Services',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1288',
        permalink: 'https://library.wmo.int/idurl/4/58125',
        pdfUrl: 'https://library.wmo.int/viewer/58125/download?file=1288_en.pdf&type=pdf&navigator=1',
        numberOfPages: 43,
      },
      {
        fileName: 'Guidelines for the Assessment of Competencies for Provision of Climate Services',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1285',
        permalink: 'https://library.wmo.int/idurl/4/58175',
        pdfUrl: 'https://library.wmo.int/viewer/58175/download?file=1285_en.pdf&type=pdf&navigator=1',
        numberOfPages: 40,
      },
      {
        fileName: 'Guidelines on Implementation of a Coastal Inundation Forecasting–Early Warning System',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1293',
        permalink: 'https://library.wmo.int/idurl/4/58169',
        pdfUrl: 'https://library.wmo.int/viewer/58169/download?file=1293_en.pdf&type=pdf&navigator=1',
        numberOfPages: 69,
      },
      {
        fileName: 'Guidelines on the Implementation of Climate Watches',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1299',
        permalink: 'https://library.wmo.int/idurl/4/58326',
        pdfUrl: 'https://library.wmo.int/viewer/58326/download?file=1299_en.pdf&type=pdf&navigator=1',
        numberOfPages: 21,
      },
      {
        fileName: 'Assessment Guidelines for End-to-End Flood Forecasting and Early Warning Systems',
        yearPublished: 2022,
        officialNumber: 'WMO-No. 1286',
        permalink: 'https://library.wmo.int/idurl/4/58245',
        pdfUrl: 'https://library.wmo.int/viewer/58245/download?file=1286_en.pdf&type=pdf&navigator=1',
        numberOfPages: 36,
      },
      {
        fileName: 'Guidelines for the Submission of the World Weather Records 2011+',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1186',
        permalink: 'https://library.wmo.int/idurl/4/55481',
        pdfUrl: 'https://library.wmo.int/viewer/55481/download?file=1186_en.pdf&type=pdf&navigator=1',
        numberOfPages: 20,
      },
      {
        fileName: 'Guidance on Integrated Urban Hydrometeorological, Climate and Environment Services - Volume II; Demonstration Cities',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1234',
        permalink: 'https://library.wmo.int/idurl/4/57316',
        pdfUrl: 'https://library.wmo.int/viewer/57316/download?file=1234-Guidance-Urban-Hydromet-Climate-Environment-Services-Volume-II_en.pdf&type=pdf&navigator=1',
        numberOfPages: 166,
      },
      {
        fileName: 'Guidelines for Public-Private Engagement',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1258',
        permalink: 'https://library.wmo.int/idurl/4/57344',
        pdfUrl: 'https://library.wmo.int/viewer/57344/download?file=1258_en.pdf&type=pdf&navigator=1',
        numberOfPages: 27,
      },
      {
        fileName: 'Guidelines on Ensemble Prediction System Postprocessing',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1254',
        permalink: 'https://library.wmo.int/idurl/4/57510',
        pdfUrl: 'https://library.wmo.int/viewer/57510/download?file=1254_Guidelines_on_EPSPP_en.pdf&type=pdf&navigator=1',
        numberOfPages: 59,
      },
      {
        fileName: 'WMO Guidelines on Multi-hazard Impact-based Forecast and Warning Services; Part II: Putting Multi-Hazard IBFWS into Practice',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1150',
        permalink: 'https://library.wmo.int/idurl/4/57739',
        pdfUrl: 'https://library.wmo.int/viewer/57739/download?file=1150_en.pdf&type=pdf&navigator=1',
        numberOfPages: 63,
      },
      {
        fileName: 'Guidelines on Surface Station Data Quality Control and Quality Assurance for Climate Applications',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1269',
        permalink: 'https://library.wmo.int/idurl/4/57727',
        pdfUrl: 'https://library.wmo.int/viewer/57727/download?file=1269_en.pdf&type=pdf&navigator=1',
        numberOfPages: 54,
      },
      {
        fileName: 'Guidelines on Seasonal Hydrological Prediction',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 1274',
        permalink: 'https://library.wmo.int/idurl/4/57915',
        pdfUrl: 'https://library.wmo.int/viewer/57915/download?file=1274_en.pdf&type=pdf&navigator=1',
        numberOfPages: 77,
      },
      {
        fileName: 'Sea-ice Information and Services',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 574',
        permalink: 'https://library.wmo.int/idurl/4/31442',
        pdfUrl: 'https://library.wmo.int/viewer/31442/download?file=574_en.pdf&type=pdf&navigator=1',
        numberOfPages: 25,
      },
      {
        fileName: 'Guide to the WMO Integrated Global Observing System',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1165',
        permalink: 'https://library.wmo.int/idurl/4/55696',
        pdfUrl: 'https://library.wmo.int/viewer/55696/download?file=1165_en.pdf&type=pdf&navigator=1',
        numberOfPages: 111,
      },
      {
        fileName: 'Guide to Instruments and Methods of Observation; Volume I – Measurement of Meteorological Variables',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 8',
        permalink: 'https://library.wmo.int/idurl/4/68695',
        pdfUrl: 'https://library.wmo.int/viewer/68695/download?file=8_I_2021_en.pdf&type=pdf&navigator=1',
        numberOfPages: 581,
      },
      {
        fileName: 'Guide to the WMO Information System',
        yearPublished: 2019,
        officialNumber: 'WMO-No. 1061',
        permalink: 'https://library.wmo.int/idurl/4/28988',
        pdfUrl: 'https://library.wmo.int/viewer/28988/download?file=1061_en.pdf&type=pdf&navigator=1',
        numberOfPages: 140,
      },
      {
        fileName: 'Guide to Instruments and Methods of Observation; Volume IV – Space based Observations',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 8',
        permalink: 'https://library.wmo.int/idurl/4/68662',
        pdfUrl: 'https://library.wmo.int/viewer/68662/download?file=8_IV_2021_en.pdf&type=pdf&navigator=1',
        numberOfPages: 195,
      },
      {
        fileName: 'Guide to Instruments and Methods of Observation; Volume III – Observing Systems',
        yearPublished: 2021,
        officialNumber: 'WMO-No. 8',
        permalink: 'https://library.wmo.int/idurl/4/68661',
        pdfUrl: 'https://library.wmo.int/viewer/68661/download?file=8_III_2021_en.pdf&type=pdf&navigator=1',
        numberOfPages: 428,
      },
      {
        fileName: 'Capacity Development for Climate Services: Guidelines for National Meteorological and Hydrological Services',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1247',
        permalink: 'https://library.wmo.int/idurl/4/57056',
        pdfUrl: 'https://library.wmo.int/viewer/57056/download?file=1247_en.pdf&type=pdf&navigator=1',
        numberOfPages: 55,
      },
      {
        fileName: 'Guide to Hydrological Practices, Volume I; Hydrology – From Measurement to Hydrological Information',
        yearPublished: 2008,
        officialNumber: 'WMO-No. 168',
        permalink: 'https://library.wmo.int/idurl/4/35804',
        pdfUrl: 'https://library.wmo.int/viewer/35804/download?file=168_Vol_I_en_1.pdf&type=pdf&navigator=1',
        numberOfPages: 296,
      },
      {
        fileName: 'Guidelines on Homogenization',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1245',
        permalink: 'https://library.wmo.int/idurl/4/57130',
        pdfUrl: 'https://library.wmo.int/viewer/57130/download?file=1245_en.pdf&type=pdf&navigator=1',
        numberOfPages: 63,
      },
      {
        fileName: 'Guide to Wave Analysis and Forecasting',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 702',
        permalink: 'https://library.wmo.int/idurl/4/31871',
        pdfUrl: 'https://library.wmo.int/viewer/31871/download?file=702_en.pdf&type=pdf&navigator=1',
        numberOfPages: 208,
      },
      {
        fileName: 'Guidance on Operational Practices for Objective Seasonal Forecasting',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 1246',
        permalink: 'https://library.wmo.int/idurl/4/57090',
        pdfUrl: 'https://library.wmo.int/viewer/57090/download?file=1246_en.pdf&type=pdf&navigator=1',
        numberOfPages: 106,
      },
      {
        fileName: 'Compendium on Tropical Meteorology for Aviation Purposes',
        yearPublished: 2020,
        officialNumber: 'WMO-No. 930',
        permalink: 'https://library.wmo.int/idurl/4/32057',
        pdfUrl: 'https://library.wmo.int/viewer/32057/download?file=930_en.pdf&type=pdf&navigator=1',
        numberOfPages: 84,
      },
    ],
  },
  {
    title: 'K. United Science',
    contentList: [
      {
        fileName: 'United In Science 2019; High-level synthesis report of latest climate science information convened by the Science Advisory Group of the UN Climate Action Summit 2019',
        yearPublished: 2019,
        officialNumber: '',
        permalink: 'https://library.wmo.int/idurl/4/56814',
        pdfUrl: 'https://library.wmo.int/viewer/56814/download?file=2019_united-science_en.pdf&type=pdf&navigator=1',
        numberOfPages: 28,
      },
      {
        fileName: 'United In Science 2021; A multi-organization high-level compilation of the latest climate science information',
        yearPublished: 2021,
        officialNumber: '',
        permalink: 'https://library.wmo.int/idurl/4/57608',
        pdfUrl: 'https://library.wmo.int/viewer/57608/download?file=WMO_United_In_Science_2021_en.pdf&type=pdf&navigator=1',
        numberOfPages: 21,
      },
      {
        fileName: 'United In Science 2022; A multi-organization high-level compilation of the most recent science related to climate change, impacts and responses',
        yearPublished: 2022,
        officialNumber: '',
        permalink: 'https://library.wmo.int/idurl/4/58075',
        pdfUrl: 'https://library.wmo.int/viewer/58075/download?file=United_In_Science_2022_HighRes_en.pdf&type=pdf&navigator=1',
        numberOfPages: 40,
      },
      {
        fileName: 'United in Science 2023; Sustainable development edition; A multi-organization high-level compilation of the latest weather-, climate and water-related sciences and services for sustainable development',
        yearPublished: 2023,
        officialNumber: '',
        permalink: 'https://library.wmo.int/idurl/4/68235',
        pdfUrl: 'https://library.wmo.int/viewer/68235/download?file=United+in+Science+2023_en.pdf&type=pdf&navigator=1',
        numberOfPages: 48,
      },
    ],
  },

];

