import React from 'react';

import { ITableDb } from './types';

export const TableDb: ITableDb = ({
  contentList,
  title }) => (
  <div
    className="table-container my-4"
    id={ title.replace(/\s+/g, '-').toLowerCase() }
  >
    <h3 className='mt-4'>{ title }</h3>
    <table className="table table-striped table-bordered table-hover table-responsive table-dark">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Year Published</th>
          <th>Official Number</th>
          <th>Permalink</th>
          <th>PDF URL Download</th>
          <th>Number of Pages</th>
        </tr>
      </thead>
      <tbody>
        { contentList.map((item, index) => (
          <tr key={ index.toString() + 'tr' }>
            <td>{ item.fileName }</td>
            <td>{ item.yearPublished }</td>
            <td>{ item.officialNumber }</td>
            <td>
              <a
                href={ item.permalink }
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </td>
            <td>
              <a
                href={ item.pdfUrl }
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </td>
            <td>{ item.numberOfPages }</td>
          </tr>
        )) }
      </tbody>
    </table>
  </div>
);
