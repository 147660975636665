/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import cnames from 'classnames';

import { loginRequest } from 'authConfig';
import './styles.scss';

export const Menu: React.FC = () => {

  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance, inProgress } = useMsal();
  const location = useLocation(); // Get the current location

  const handleLoginRedirect = () => {
    // eslint-disable-next-line no-console
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  // Function to determine if a menu item is active
  const isActive = useCallback((path: string) => location.pathname === path, [location.pathname]);

  useEffect(() => {
    if (instance) {
      const activeAccount = instance.getActiveAccount();
      // eslint-disable-next-line no-console
      console.log(activeAccount);
      if (activeAccount && window.location.href.includes('auth')) {
        window.location.href = '/';
      } else if (!activeAccount && window.location.href.includes('logout')) {
        window.location.href = '/';
      }
    }
  }, [instance, inProgress, window.location.href]);


  return (
    <nav
      className={
        cnames(
          'menu',
          'd-flex flex-column justify-content-between align-items-start',
          'py-4 fs-2 gap-3',
          { 'open': isOpen },
        ) }
    >
      <ul className='d-flex list-unstyled d-flex flex-column m-0 '>
        <li onClick={ () => setIsOpen(open => !open) }>
          <i className="bi bi-list" />
        </li>
      </ul>
      <ul
        className={ cnames(
          'menu__links gap-3 list-unstyled flex-column m-0',
          isOpen ? 'd-flex' : 'd-sm-flex d-none',
        ) }
      >
        <li>
          <Link
            className='text-decoration-none d-flex gap-2 align-items-center new-chat'
            to=''
            onClick={ () => window.location.href = '/' }
          >
            <i className="bi bi-plus" />
            { isOpen ? <span className='fs-4'>New chat</span> : undefined }
          </Link>
        </li>
        <li className={ isActive('/') ? 'active' : '' }>
          <Link
            className='text-decoration-none d-flex gap-2 align-items-center'
            to="/"
          >
            <i className="bi bi-house-door-fill" />
            { isOpen ? <span className='fs-4'>Home</span> : undefined }
          </Link>
        </li>
        <li className={ isActive('/disclaimer') ? 'active' : '' }>
          <Link
            className='text-decoration-none d-flex gap-2 align-items-center'
            to="/disclaimer"
          >
            <i className="bi bi-exclamation-triangle-fill" />
            { isOpen ? <span className='fs-4'>Disclaimer</span> : undefined }
          </Link>
        </li>
        <li className={ isActive('/db') ? 'active' : '' }>
          <Link
            className='text-decoration-none d-flex gap-2 align-items-center'
            to="/db"
          >
            <i className="bi bi-server" />
            { isOpen ? <span className='fs-4'>Database</span> : undefined }
          </Link>
        </li>
        <AuthenticatedTemplate>
          <li>
            <Link
              className='text-decoration-none d-flex gap-2 align-items-center'
              to=''
              onClick={ handleLogoutRedirect }
            >
              <i className="bi bi-person-circle" />
              { isOpen ? <span className='fs-4'>Log out</span> : undefined }
            </Link>
          </li>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <li>
            <Link
              className='text-decoration-none d-flex gap-2 align-items-center'
              to=''
              onClick={ handleLoginRedirect }
            >
              <i className="bi bi-person-fill" />
              { isOpen ? <span className='fs-4'>Log in / Sign up</span> : undefined }
            </Link>
          </li>
        </UnauthenticatedTemplate>
      </ul>
    </nav>);
};