import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Database, Disclaimer, Chat } from 'components';

export const Pages: React.FC = () => (
  <div className='pages d-flex flex-column flex-fill'>
    <Routes>
      <Route
        path="/"
        element={
          <Chat />
        }
      />
      <Route
        path="/q/:id"
        element={
          <Chat />
        }
      />
      <Route
        path="/disclaimer"
        element={
          <Disclaimer />
        }
      />
      <Route
        path="/db"
        element={
          <Database />
        }
      />
    </Routes>
  </div>
);